/* import __COLOCATED_TEMPLATE__ from './form-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Admin from 'embercom/models/admin';

export default class FormComponent extends Component {
  @service gtmService;
  @service purchaseAnalyticsService;
  @service router;
  @service intl;
  @service notificationsService;

  context = 'signup';
  place = 'create_account';

  @tracked name = this.args.name || '';
  @tracked email = this.args.email || '';
  @tracked password = this.args.password;
  @tracked appName = this.args.appName || '';
  @tracked consentProvided = !this.consentRequired;

  @tracked adminErrors = EmberObject.create({
    name: null,
    email: null,
    password: null,
  });

  @tracked appErrors = EmberObject.create({
    name: null,
  });

  @tracked networkError;

  constructor() {
    super(...arguments);
  }

  resetErrors() {
    this.adminErrors = EmberObject.create({});
    this.appErrors = EmberObject.create({});
    this.networkError = null;
  }

  handleCreationError(response) {
    if (!response) {
      return;
    }

    this.resetErrors();

    response = response.jqXHR ? response.jqXHR : response;

    if (response.status >= 500) {
      this.networkError = this.intl.t(
        'signup.teams.start.form.network_errors.something_went_wrong',
      );
      return;
    }

    if (response.status === 429) {
      this.networkError = this.intl.t(
        'signup.teams.start.form.network_errors.too_many_signup_attempts',
      );
      return;
    }

    if (response.status === 409) {
      // see https://github.com/intercom/intercom/issues/273996
      // the 409 error is returned if the app is already created
      // reloading will redirect the user to the correct route
      this.reloadPage();
      return;
    }

    let responseJSON = response.responseJSON;

    if (!responseJSON) {
      return;
    }

    let {
      unsupported_locale: unsupportedLocale,
      adblocker,
      admin,
      app,
      email,
      name,
      password,
    } = responseJSON;

    if (adblocker) {
      this.networkError = this.intl.t('signup.teams.start.form.network_errors.ad_blocker');
      return;
    }

    if (unsupportedLocale) {
      this.networkError = this.intl.t('signup.teams.start.form.network_errors.unsupported_locale');
      return;
    }

    if (admin) {
      this.adminErrors = admin;
      if (this.adminErrors) {
        if (this.adminErrors.email) {
          if (this.adminErrors.email === 'Email has already been taken') {
            this.adminErrors.email = this.intl.t(
              'signup.teams.start.form.errors.email_already_taken',
            );
          } else {
            this.adminErrors.email = this.intl.t('signup.teams.start.form.errors.invalid_email');
          }
        }
        if (this.adminErrors.password) {
          this.adminErrors.password = this.intl.t(
            'signup.teams.start.form.errors.invalid_password',
          );
        }
      }
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'teammate_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: admin,
      });
    }

    if (app) {
      this.appErrors = app;
      if (this.appErrors.name) {
        this.appErrors.name = this.intl.t('signup.teams.start.form.errors.invalid_app_name');
      }
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'app_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: app,
      });
    }

    if (email || name || password) {
      this.adminErrors = {
        email,
        name,
        password,
      };
    }
  }

  trackDeveloperWorkspaceClickedEvent() {
    if (this.router.currentRouteName === 'developer-signup.index') {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        context: this.context,
        object: 'sign_up_button',
        place: 'developer_signup',
      });
    }
  }

  trackEvent(email) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'register',
      context: this.context,
      place: 'create_account',
      mobile: this.args.mobileDevice,
      gclid: this.args.gclid,
      emailSubmissionId: this.args.emailSubmissionId,
      email: email || this.email,
      developerSignup: this.args.isDeveloper,
    });
  }

  get talonValue() {
    return document.getElementById('talon')?.value;
  }

  get consentRequired() {
    return this.args.cookieConsentMode === 'consent-required';
  }

  @action
  startGoogleAuth() {
    window.location.href = `/auth/google_oauth2${window.location.search}`;
  }

  @action
  async submitForm(e) {
    this.resetErrors();

    if (this.clientSideErrorsExist()) {
      return;
    }

    if (this.args.authenticatedAdmin) {
      let admin = await Admin.meWithoutRedirect();
      this.trackEvent(admin.email);
    } else {
      this.trackEvent();
    }

    this.trackDeveloperWorkspaceClickedEvent();
    e && typeOf(e.preventDefault) === 'function' && e.preventDefault();

    let formData = {
      name: this.name,
      email: this.email,
      password: this.password,
      appName: this.appName,
      talon: this.talonValue,
      consentProvided: this.consentProvided,
    };

    this.args.formTask.perform(formData, this.handleCreationError.bind(this));
  }

  @action
  trackEnteringEvent(object) {
    let event = {
      action: 'entered',
      object,
      context: 'signup',
      place: this.place,
      mobile: this.args.mobileDevice,
      developer: this.args.isDeveloper,
    };

    this.purchaseAnalyticsService.trackEvent(event);
  }

  @action
  focusOutOfName() {
    this.resetErrors();
    this.trackEnteringEvent('name');
    this.validateName();
  }

  clientSideErrorsExist() {
    return this.validateName() === false;
  }

  validateName() {
    if (!this.isNameValid()) {
      this.adminErrors = {
        name: this.intl.t('signup.teams.start.form.errors.invalid_admin_name'),
      };
      return false;
    }
    return true;
  }

  isNameValid() {
    if (this.adminFieldsNotNeeded()) {
      return true;
    }

    return this.strContains2Words(this.name);
  }

  adminFieldsNotNeeded() {
    // name, email, password
    return this.args.authenticatedAdmin;
  }

  strContains2Words(str) {
    return str.trim().indexOf(' ') !== -1;
  }

  reloadPage() {
    window.location.reload();
  }
}
