/* import __COLOCATED_TEMPLATE__ from './stripe-component.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { captureException } from 'embercom/lib/sentry';

import type StripeIntent from 'embercom/models/billing/stripe-intent';
import type Store from '@ember-data/store';

export interface AfterPaymentMethodSuccess {
  paymentMethod: string;
  stripeIntent?: StripeIntent;
  address: {
    countryCode: string;
    streetAddress: string;
    stateCode?: string;
    postCode?: string;
    city?: string;
  };
}

interface Args {
  redirectUrl: string;
  amount: number;
  afterSuccessTask: (afterSuccessPayload: AfterPaymentMethodSuccess) => TaskGenerator<void>;
}

interface AddressResultFromStripeElements {
  city?: string;
  country?: string;
  line1?: string;
  postal_code?: string;
  state?: string;
}

interface StripeElement {
  mount: (selector: string) => void;
}

enum addressValidationCountryCodes {
  unitedStates = 'US',
  canada = 'CA',
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    default: [
      {
        paymentElement: any;
        confirmButton: any;
      },
    ];
  };
}

export default class StripeComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: any;
  @service declare stripev3: any;
  @service declare notificationsService: any;
  @service declare router: Router;

  @service declare store: Store;
  @service declare purchaseAnalyticsService: any;

  @tracked elements: any;
  @tracked confirmingPaymentMethod = false;
  @tracked paymentElementReference!: StripeElement;
  @tracked addressElementReference!: StripeElement;

  @tracked stripeIntent?: StripeIntent;
  @tracked amount = 100;

  // billing address attributes
  @tracked countryCode?: string;
  @tracked stateCode?: string;
  @tracked postCode?: string;
  @tracked city?: string;
  @tracked streetAddress?: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.configureStripeElements).perform();
  }

  get isMountingElements() {
    return taskFor(this.mountStripeElements).isRunning;
  }

  private setLoading(loading: boolean) {
    this.confirmingPaymentMethod = loading;
    let addressElement = document.getElementById('address-element');
    let paymentElement = document.getElementById('payment-element');
    if (addressElement) {
      addressElement.style.visibility = loading ? 'hidden' : 'visible';
    }
    if (paymentElement) {
      paymentElement.style.visibility = loading ? 'hidden' : 'visible';
    }
  }

  private addressRequiresValidationForCountry(countryCode: string) {
    if (this.appService.app.disableFrontendAddressValidation) {
      return false;
    }

    let countryCodesRequiringChecks: string[] = Object.values(addressValidationCountryCodes);
    return countryCodesRequiringChecks.includes(countryCode);
  }

  @action
  setAddress(addressResult: AddressResultFromStripeElements) {
    this.countryCode = addressResult.country;
    this.stateCode = addressResult?.state ? addressResult?.state : undefined;
    this.postCode = addressResult?.postal_code ? addressResult?.postal_code : undefined;
    this.city = addressResult?.city ? addressResult.city : undefined;
    this.streetAddress = addressResult.line1;
  }

  @keepLatestTask
  *mountStripeElements(): TaskGenerator<void> {
    if (!(this.paymentElementReference && this.addressElementReference)) {
      yield taskFor(this.configureStripeElements).perform();
    }

    try {
      this.paymentElementReference.mount('#payment-element');
      this.addressElementReference.mount('#address-element');
    } catch (err) {
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      console.error(err);
    }
  }

  @keepLatestTask
  *configureStripeElements(): TaskGenerator<void> {
    try {
      let stripeIntent = yield taskFor(this.createPaymentIntent).perform(this.amount);
      let key = this.getStripeKey();

      if (this.stripev3.stripeOptions?.stripeAccount !== stripeIntent.stripeAccountId) {
        this.stripev3._stripe = null;
      }
      yield this.stripev3.load(key, { stripeAccount: stripeIntent.stripeAccountId });

      let options = {
        loader: 'auto',
        appearance: {
          theme: 'minimal',
        },
        clientSecret: stripeIntent.clientSecret,
      };

      this.elements = this.stripev3.elements(options);

      let addressOptions = { mode: 'billing', fields: { name: 'never' } };

      let paymentElementOptions = {
        layout: 'tabs',
      };

      this.addressElementReference = this.elements.create('address', addressOptions);
      this.paymentElementReference = this.elements.create('payment', paymentElementOptions);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      console.error(err);
    }
  }

  @dropTask
  *confirmPayment(): TaskGenerator<void> {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'confirm_payment',
      place: 'stripe-payment-method',
      context: {
        payment_intent: this.stripeIntent?.isPaymentIntent,
        app_id: this.appService.app.id,
      },
    });

    this.setLoading(true);
    let addressElement = this.elements.getElement('address');

    let addressResult = yield addressElement.getValue();
    this.setAddress(addressResult.value.address);
    if (this.addressRequiresValidationForCountry(this.countryCode ?? '')) {
      try {
        yield taskFor(this.validateAddress).perform();
      } catch (err) {
        this.setLoading(false);
        this.purchaseAnalyticsService.trackEvent({
          action: 'errored',
          object: 'pay_now_button',
          context: 'address_validation',
          place: 'stripe-payment-method',
        });
        return;
      }
    }

    if (this.args.amount !== this.stripeIntent?.amount && this.stripeIntent?.isPaymentIntent) {
      try {
        yield taskFor(this.updatePaymentIntent).perform(this.args.amount);
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
        );
        captureException(err, {
          tags: {
            responsibleTeam: 'team-purchase-experience',
            responsible_team: 'team-purchase-experience',
          },
        });
        console.error(err);
        return;
      }
    }

    let response;
    try {
      response = yield taskFor(this.confirmSetup).perform({
        elements: this.elements,
        url: this.args.redirectUrl,
      });
    } catch (err) {
      this.setLoading(false);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
      );
      console.error(err);
      return;
    }
    //This code will never be hit if the payment method requires a redirect
    if (response.error) {
      this.setLoading(false);
      this.handleError(response.error);
      this.purchaseAnalyticsService.trackEvent({
        action: 'errored',
        object: 'confirm_payment',
        context: {
          error_type: response.error?.type,
          decline_code: response.error?.decline_code,
          error_code: response.error?.code,
        },
        place: 'stripe-payment-method',
      });
      return;
    }
    let paymentMethod;
    if (this.stripeIntent!.isPaymentIntent) {
      paymentMethod = response.paymentIntent.payment_method;
    } else {
      paymentMethod = response.setupIntent.payment_method;
    }
    let afterSuccessPayload: AfterPaymentMethodSuccess = {
      paymentMethod,
      stripeIntent: this.stripeIntent,
      address: {
        countryCode: this.countryCode!,
        streetAddress: this.streetAddress!,
        stateCode: this.stateCode,
        postCode: this.postCode,
        city: this.city,
      },
    };
    yield taskFor(this.args.afterSuccessTask).perform(afterSuccessPayload);
  }

  @keepLatestTask
  *confirmSetup({ elements, url }: { elements: any; url: string }): TaskGenerator<any> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    yield this.stripev3.load(this.getStripeKey(), {
      stripeAccount: this.stripeIntent.stripeAccountId,
    });

    let params = {
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: url,
      },
    };
    let response;
    if (this.stripeIntent.isPaymentIntent) {
      response = yield this.stripev3.instance.confirmPayment(params);
    } else {
      response = yield this.stripev3.instance.confirmSetup(params);
    }
    return response;
  }

  @keepLatestTask
  *createPaymentIntent(amount_in_cent: number): TaskGenerator<StripeIntent> {
    let params: { [key: string]: any } = {
      app_id: this.appService.app.id,
      amount_in_cent,
    };

    try {
      let response = yield post('/ember/customers/create_stripe_intent_for_checkout', params);
      this.store.pushPayload('billing/stripe-intent', {
        'billing/stripe-intent': [response],
      });
      this.stripeIntent = this.store.peekRecord('billing/stripe-intent', response.object_id)!;
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *updatePaymentIntent(newAmount: number): TaskGenerator<StripeIntent> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    if (!this.stripeIntent.isPaymentIntent) {
      return this.stripeIntent;
    }

    let params = {
      payment_intent_id: this.stripeIntent.id,
      amount_in_cent: newAmount,
      app_id: this.appService.app.id,
    };
    try {
      let response = yield post('/ember/customers/update_payment_intent', params);
      this.stripeIntent.updateFromPayload(response);
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *validateAddress(): TaskGenerator<AddressResultFromStripeElements> {
    let params = {
      country_code: this.countryCode,
      state_code: this.stateCode,
      postcode: this.postCode,
      city: this.city,
      street_address: this.streetAddress,
      app_id: this.appService.app.id,
    };
    let response;
    try {
      response = yield post('/ember/customers/validate_address', params);
      return response;
    } catch (err) {
      captureException(err);
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
      );
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      throw err;
    }
  }

  private handleError(error: any) {
    switch (error?.type) {
      case 'card_error':
      case 'validation_error':
        if (error.decline_code === 'lost_card' || error.decline_code === 'stolen_card') {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.stripe.lost-stolen-card'),
          );
        } else {
          this.notificationsService.notifyError(error.message);
        }
        break;
      default:
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
        break;
    }
  }

  private _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  private getStripeKey() {
    if (this._isStaging()) {
      return ENV.APP.stripe.sandboxPublishableKey;
    }
    return this.stripev3.publishableKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::StripeComponent': typeof StripeComponent;
  }
}
