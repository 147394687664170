/* import __COLOCATED_TEMPLATE__ from './reaction-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { inject as service } from '@ember/service';

export default class ReactionTitle extends Component {
  @service intl;
  get filterItems() {
    let option = [
      {
        text: this.intl.t('stats-system.list.header.bulk-actions-buttons.reacted'),
        value: '',
      },
    ];
    let postReactions = this.args.statsParameters.contentObject.reactionSet.map((reaction) => {
      return {
        text: this.intl.t('stats-system.list.header.bulk-actions-buttons.reacted-with', {
          unicode_emoticon: reaction.unicode_emoticon,
        }),
        value: reaction.unicode_emoticon,
      };
    });
    return [...option, ...postReactions];
  }

  get selectedFilterValue() {
    let values = this.args.statsParameters.contentObject.reactionSet.map(
      (reaction) => reaction.unicode_emoticon,
    );
    return values.includes(this.args.filter) ? this.args.filter : '';
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.reaction, filter);
  }
}
