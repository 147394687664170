/* import __COLOCATED_TEMPLATE__ from './navbar.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

interface Args {}

export default class StandaloneNavbar extends Component<Args> {
  @service declare appService: any;
  @tracked app: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.app = this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar': typeof StandaloneNavbar;
    'standalone::navbar': typeof StandaloneNavbar;
  }
}
