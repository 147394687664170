/* import __COLOCATED_TEMPLATE__ from './zendesk-api-config.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {}

export default class StandaloneZendeskApiConfig extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: any;

  @action async revokeAccess() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: 'Are you sure you want to revoke access to Zendesk? Fin will immediately stop responding to tickets.',
      confirmButtonText: 'Revoke Access',
    });

    try {
      if (isConfirmed) {
        await this.zendeskConfig.revoke();
        this.notificationsService.notifyConfirmation('Access to Zendesk has been revoked');
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't revoke access to Zendesk. Please try again`,
      });
    }
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::ZendeskApiConfig': typeof StandaloneZendeskApiConfig;
    'standalone/setup/zendesk-api-config': typeof StandaloneZendeskApiConfig;
  }
}
