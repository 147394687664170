/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    isDisabled?: boolean;
    tooltipContent?: string;
    showTooltip?: boolean;
  };
  Blocks: {
    leftPane: [];
    rightPane: [];
    footer: [];
  };
}

const StandalonePanel = templateOnlyComponent<Signature>();
export default StandalonePanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::Panel': typeof StandalonePanel;
  }
}
