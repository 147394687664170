/* import __COLOCATED_TEMPLATE__ from './description-cell.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AppAdminEventsHelper from 'embercom/lib/admins/app-admin-events-helper';
import { getOwner } from '@ember/application';

export default class DescriptionCell extends Component {
  @service appService;

  get event() {
    return this.args.row.event || this.args.row;
  }

  get app() {
    return this.appService.app;
  }

  get cellText() {
    let helper = new AppAdminEventsHelper(getOwner(this));
    return helper.descriptionHTML(this.event);
  }
}
