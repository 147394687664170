/* import __COLOCATED_TEMPLATE__ from './payment-method.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type TaskGenerator } from 'ember-concurrency';

interface Signature {
  Args: {
    confirmingPaymentMethod: boolean;
    isMountingElements: boolean;
    mountStripeElements: () => TaskGenerator<void>;
  };
  Blocks: {
    default: [];
  };
}

const PaymentMethod = templateOnlyComponent<Signature>();
export default PaymentMethod;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::PaymentMethod': typeof PaymentMethod;
    'stripe/payment-method': typeof PaymentMethod;
  }
}
