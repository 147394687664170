/* import __COLOCATED_TEMPLATE__ from './base-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import { dasherize, capitalize } from '@ember/string';
import { task } from 'ember-concurrency-decorators';
import { objectWithVersions } from 'embercom/models/data/matching-system/matching-constants';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class BaseStatList extends Component {
  @service appService;
  @service contentEditorService;
  @service intercomEventService;
  @service store;

  @tracked statRows = [];
  @tracked totalCount = 0;
  @tracked uniqueStatsRows = [];
  @tracked uniqueCount = 0;

  @tracked selectedLocalizedContentId = '';
  @tracked selectedHelpCenterId = this.args.selectedHelpCenterId;

  get app() {
    return this.appService.app;
  }

  get columns() {
    if (this.args.overrideColumns) {
      return this.args.overrideColumns;
    }

    let columns = [{ label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' }];

    if (this.showCompanyColumn) {
      columns.push({
        label: `${capitalize(this.args.verb)} for company`,
        valuePath: 'company.name',
      });
    }

    columns.push({ label: `When it was ${this.args.verb}`, valuePath: 'createdAt' });

    if (!this.args.hideCreatedVia) {
      columns.push({
        label: `How it was ${this.args.verb}`,
        valuePath: 'createdVia',
      });
    }

    if (objectWithVersions.includes(this.args.statsParameters.contentType)) {
      columns.push({ label: 'Version', valuePath: 'versionIndex' });
    }

    return columns;
  }

  get grouped() {
    if (this.args.grouped) {
      return this.args.grouped;
    }
    return false;
  }

  get hasItemsToLoad() {
    return this.statRows.length < this.totalCount;
  }

  get isLoading() {
    return this.statRows.length === 0 && this._fetchStats.isRunning;
  }

  get selectedVersion() {
    return this.contentEditorService.selectedVersion;
  }

  get loadingRowCount() {
    if (this.totalCount > 25) {
      return 25;
    } else {
      return this.totalCount;
    }
  }

  get additionalFiltersWithVersionId() {
    let additional_filters = {};
    if (this.args.additionalFilters) {
      additional_filters = this.args.additionalFilters;
    }
    if (this.selectedVersion) {
      additional_filters['ruleset_version.id'] = this.selectedVersion.get('id');
    }
    return additional_filters;
  }

  get showCompanyColumn() {
    let hasCompanyData = this.contentEditorService.activeRulesetLink?.object?.hasCompanyAttributes;
    if (hasCompanyData !== undefined) {
      return hasCompanyData;
    }
    if (this.args.hideCompanyColumn) {
      return false;
    }
    return true;
  }

  get additionalFiltersWithLocalizedContentId() {
    let additionalFilters = {};
    if (this.args.additionalFilters) {
      additionalFilters = this.args.additionalFilters;
    }
    if (this.selectedLocalizedContentId) {
      additionalFilters['stat_receipt.localized_content_id'] = this.selectedLocalizedContentId;
    }
    return additionalFilters;
  }

  get additionalFiltersWithHelpCenterId() {
    let additionalFilters = {};
    if (this.args.additionalFilters) {
      additionalFilters = this.args.additionalFilters;
    }
    if (this.selectedHelpCenterId) {
      additionalFilters['article_stat_metadata.help_center_id'] = parseInt(
        this.selectedHelpCenterId,
        10,
      );
    }
    return additionalFilters;
  }

  get additionalFiltersWithTargetChannel() {
    let additionalFilters = {};
    if (this.args.additionalFilters) {
      additionalFilters = this.args.additionalFilters;
    }
    if (this.args.targetChannels) {
      additionalFilters['custom_bot_conversation_state.channel'] = this.args.targetChannels;
    }
    return additionalFilters;
  }

  get baseQueryParams() {
    let baseQueryParams = {
      content_id: this.args.statsParameters.contentId,
      content_type: this.args.statsParameters.contentType,
    };

    if (
      this.args.existsFieldsFilters?.exists_fields &&
      this.args.existsFieldsFilters.exists_fields !== ['stat_receipt.id']
    ) {
      baseQueryParams.exists_fields = this.args.existsFieldsFilters.exists_fields;
    }
    if (Object.keys(this.additionalFiltersWithVersionId).length) {
      baseQueryParams['additional_filters'] = this.additionalFiltersWithVersionId;
    }

    if (Object.keys(this.additionalFiltersWithLocalizedContentId).length) {
      baseQueryParams['additional_filters'] = {
        ...baseQueryParams['additional_filters'],
        ...this.additionalFiltersWithLocalizedContentId,
      };
    }
    if (Object.keys(this.additionalFiltersWithHelpCenterId).length) {
      baseQueryParams['additional_filters'] = {
        ...baseQueryParams['additional_filters'],
        ...this.additionalFiltersWithHelpCenterId,
      };
    }
    if (Object.keys(this.additionalFiltersWithTargetChannel).length) {
      baseQueryParams['additional_filters'] = {
        ...baseQueryParams['additional_filters'],
        ...this.additionalFiltersWithTargetChannel,
      };
    }
    return baseQueryParams;
  }

  get statCount() {
    return this.args.showUniqueUsersOnly ? this.uniqueCount : this.totalCount;
  }

  get statMetadata() {
    return {
      uniqueCount: this.uniqueCount,
      totalCount: this.totalCount,
    };
  }

  get aggregationType() {
    if (this.args.aggregationType) {
      return this.args.aggregationType;
    }

    if (this.args.showUniqueUsersOnly) {
      return 'cardinality';
    }

    return null;
  }

  get aggregationField() {
    if (this.args.aggregationField) {
      return this.args.aggregationField;
    }

    if (this.args.showUniqueUsersOnly) {
      return 'user.id';
    }

    return null;
  }

  constructor() {
    super(...arguments);
    this._fetch();
  }

  _fetch() {
    this.statRows = [];
    this.totalCount = 0;
    this.uniqueStatsRows = [];
    this.uniqueCount = 0;

    this._fetchStats.perform();
  }

  _removeDuplicateUser(users) {
    users = users.toArray().uniqBy('user.userId');
    let currentUserList = this.uniqueStatsRows.map(function (matchedUser) {
      return matchedUser.get('user.userId');
    });
    let uniqueUsers = users.filter(function (user) {
      if (currentUserList.indexOf(user.get('user.userId')) === -1) {
        return user;
      }
    });
    return uniqueUsers;
  }

  @task({ drop: true })
  *_fetchStats() {
    let queryPath = `stats-system/${dasherize(this.args.statistic)}`;
    let queryParams = {
      ...this.baseQueryParams,
      from: this.statRows.length,
      include_total: true,
    };

    if (isPresent(this.args.statsParameters.range)) {
      queryParams['range_start'] = this.args.statsParameters.range.start;
      queryParams['range_end'] = this.args.statsParameters.range.end;
    }

    if (this.grouped) {
      queryPath = 'stats-system/grouped-stat';
      queryParams['grouped_stat'] = this.args.statistic;
      queryParams['grouped'] = true;
    }

    yield this.store.query(queryPath, queryParams).then((result) => {
      let uniqueUsersList = this._removeDuplicateUser(result);
      this.statRows.pushObjects(result.toArray());
      this.totalCount = result.get('meta.total');
      this.uniqueCount = result.get('meta.unique_user');
      this.uniqueStatsRows.pushObjects(uniqueUsersList);
    });
  }

  @action
  nextPage() {
    if (this.hasItemsToLoad) {
      return this._fetchStats.perform();
    }
  }

  @action
  updateUsersList() {
    this._fetch();
  }

  @action
  filterByLocalizedContentId(localizedContentId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'localized_content_selected',
      object: 'stats_list',
      context: this.args.trackingContext,
      metadata: {
        localized_content_id: localizedContentId,
        content_type: this.args.statsParameters.contentType,
      },
    });
    this.selectedLocalizedContentId = localizedContentId;
    this.args.filterByLocalizedContentId(this.selectedLocalizedContentId);
  }

  @action
  filterByHelpCenterId(helpCenterId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'help_center_selected',
      object: 'stats_list',
      context: this.args.trackingContext,
      metadata: {
        help_center_id: helpCenterId,
      },
    });
    this.selectedHelpCenterId = helpCenterId;
    this.args.filterByHelpCenterId(this.selectedHelpCenterId);
  }
}
