/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import isValidUrl from 'embercom/lib/url-validator';

interface Args {}

export default class StandaloneSetupTesting extends Component<Args> {
  @trackedInLocalStorage({ keyName: 'fin-standalone-zd-sunshine-web-url' })
  websitePreviewUrl = '';

  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked isSelectingUser = false;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get emailAddress() {
    return this.finStandaloneService.zendeskTicketsSetupData?.email_addresses?.[0] || '';
  }

  @action setWebsitePreviewUrl(value: string) {
    this.websitePreviewUrl = value;
  }

  get isWebsitePreviewUrlValid() {
    return isValidUrl(this.websitePreviewUrl);
  }

  @action testFin() {
    let urlObj = new URL(this.websitePreviewUrl);
    urlObj.searchParams.set('fin_test_mode', 'true');
    let appendedUrl = urlObj.toString();

    safeWindowOpen(appendedUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Testing': typeof StandaloneSetupTesting;
    'standalone/setup/testing': typeof StandaloneSetupTesting;
  }
}
