/* import __COLOCATED_TEMPLATE__ from './fin-user-selection.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {}

export default class StandaloneSetupTicketsFinUserSelection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked isSelectingUser = false;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData && !this.adminOptions.length;
  }

  get adminOptions() {
    let admins = this.ticketsSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    return admins || [];
  }

  // Pulse treats null as a real value and errors if there isn't an associated selection
  // This returns undefined in the case where there is no selection
  get selectedAgentId(): number | undefined {
    if (this.zendeskConfig.ticketsIntegration.finZendeskUserId) {
      return this.zendeskConfig.ticketsIntegration.finZendeskUserId;
    }
    return undefined;
  }

  @action async updateAgentId(agentId: number) {
    try {
      this.isSelectingUser = true;
      await this.zendeskConfig.setFinUser(agentId);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't set your Fin user. Please try again`,
      });
      console.error(e);
    } finally {
      this.isSelectingUser = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Tickets::FinUserSelection': typeof StandaloneSetupTicketsFinUserSelection;
    'standalone/setup/tickets/fin-user-selection': typeof StandaloneSetupTicketsFinUserSelection;
  }
}
