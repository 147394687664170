/* import __COLOCATED_TEMPLATE__ from './date-cell.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DateCell extends Component {
  @service appService;

  get event() {
    return this.args.row.event || this.args.row;
  }

  get appTimezone() {
    return this.appService.app.timezone;
  }
}
