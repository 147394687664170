/* import __COLOCATED_TEMPLATE__ from './billing-addressees-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BillingAddresseesChangeDetails extends Component {
  @service appService;
  @service intl;
  @tracked showModal = false;

  emailsBeforeChangeSet = new Set(this._emailsBeforeChange);
  emailAfterChangeSet = new Set(this.emailsAfterChange);

  get emailAdded() {
    return [...this.emailAfterChangeSet].filter((email) => !this.emailsBeforeChangeSet.has(email));
  }

  get emailRemoved() {
    return [...this.emailsBeforeChangeSet].filter((email) => !this.emailAfterChangeSet.has(email));
  }

  get _emailsBeforeChange() {
    return this.args.event.value.old_emails.split(', ');
  }

  get emailsAfterChange() {
    return this.args.event.value.new_emails.split(', ');
  }
}
