/* import __COLOCATED_TEMPLATE__ from './submenu-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import showUnderConstruction from 'embercom/lib/show-under-construction';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  router: service(),
  classNames: ['submenu', 'flex-none', 'flex', 'flex-col'],
  showUnderConstructionBanner: computed(
    'app.{isDeveloperWorkspace,isTestApp}',
    'canSeeTestBanners',
    'router',
    function () {
      if (this.canSeeTestBanners) {
        return showUnderConstruction(this.app, this.router);
      }
      return false;
    },
  ),
  classNameBindings: [
    'showUnderConstructionBanner:o__under-construction',
    'navbarCollapsingService.collapsed:o__without-submenu',
  ],
  navbarCollapsingService: service(),

  didInsertElement() {
    this._super(...arguments);
    let submenuTitle = this.title;
    this.navbarCollapsingService.setActiveSubmenu(submenuTitle);
  },

  canSeeTestBanners: computed('canSeeTestBanner', function () {
    if (this.canSeeTestBanner === undefined) {
      return true;
    }
    return this.canSeeTestBanner;
  }),
});
