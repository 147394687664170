/* import __COLOCATED_TEMPLATE__ from './behavior.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';

import { inject as service } from '@ember/service';

export default class StandaloneSetupBehavior extends Component {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Behavior': typeof StandaloneSetupBehavior;
    'standalone/setup/behavior': typeof StandaloneSetupBehavior;
  }
}
