/* import __COLOCATED_TEMPLATE__ from './billing-addressees-bounced-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BillingAddresseesBouncedDetails extends Component {
  @service intl;
  @tracked showModal = false;

  get removedEmails() {
    return this.args.event.value.removed_email_addresses;
  }
}
