/* import __COLOCATED_TEMPLATE__ from './ip-allowlist-setting-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class IpAllowlistSettingChangeDetails extends Component {
  @service intl;

  @tracked showModal = false;

  get previousIpList() {
    return this.intl.t(
      'table-cells.admin-events.ip-allowlist-setting-change-details.ip-allowlist',
      { ipAllowlist: this._ipText(this.args.event.value.previous_list) },
    );
  }

  get newIpList() {
    return this.intl.t(
      'table-cells.admin-events.ip-allowlist-setting-change-details.ip-allowlist',
      { ipAllowlist: this._ipText(this.args.event.value.new_list) },
    );
  }

  _ipText(list) {
    if (list === null || list === undefined || list.length === 0) {
      return this.intl.t('table-cells.admin-events.ip-allowlist-setting-change-details.no-ips');
    }
    return list.map((ip) => ip).join(', ');
  }
}
