/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';

interface Args {}

export default class StandaloneSetupActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked isSelectingUser = false;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  @action async activateFinForTickets() {
    try {
      await this.zendeskConfig.activateForTickets();
      this.notifyConfirmation('Fin has been enabled for Zendesk tickets');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't activate Fin. Please try again`,
      });
      console.error(e);
    }
  }

  @action async deactivateFinForTickets() {
    try {
      await this.zendeskConfig.deactivateForTickets();
      this.notifyInfo('Fin has been disabled for Zendesk tickets');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't deactivate Fin. Please try again`,
      });
    }
  }

  @action async activateFinForMessenger() {
    try {
      await this.zendeskConfig.activateForMessenger();
      this.notifyConfirmation('Fin has been enabled for Zendesk messenger');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't activate Fin. Please try again`,
      });
    }
  }

  @action async deactivateFinForMessenger() {
    try {
      await this.zendeskConfig.deactivateForMessenger();
      this.notifyConfirmation('Fin has been disabled for Zendesk messenger');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't deactivate Fin. Please try again`,
      });
    }
  }

  notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Activation': typeof StandaloneSetupActivation;
    'standalone/setup/Activation': typeof StandaloneSetupActivation;
  }
}
