/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* eslint-disable ember/no-empty-glimmer-component-classes */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  route: string;
  label: string;
  icon: InterfaceIconName;
}

export default class StandaloneNavbarLink extends Component<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar::Link': typeof StandaloneNavbarLink;
    'standalone/navbar/link': typeof StandaloneNavbarLink;
  }
}
