/* import __COLOCATED_TEMPLATE__ from './basket.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import _ from 'underscore';

import type { PricingMetricRange } from 'embercom/models/quote';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  yearlyBillingPeriodSelected: boolean;
  showDiscount: boolean;
  selectedPlanId: string;
  seatNumber: number;
  selectedProactiveAddon: boolean;
  selectedCopilotAddon: boolean;
  selectedNumberOfCopilotAdmins: number;
  fullSeatPrice: number;
  loading: boolean;
  discountedSeatPrice: number;
  fullProactiveAddonPrice: number;
  discountedProactiveAddonPrice: number;
  fullCopilotAddonPrice: number;
  discountedCopilotAddonPrice?: number;
  showCreditCardPage: boolean;
  phoneMetricPrice: string;
  finMetricPrice: string;
  emailMetricPrice: PricingMetricRange;
  smsMetricPrice: PricingMetricRange;
  whatsAppMetricPrice: PricingMetricRange;
  proactiveSupportMetricPrice: PricingMetricRange;
  proactiveSupportBaseUsage: string;
  disablePaymentButton: boolean;
  monthlyFullSeatPrice: number;
  monthlyDiscountedSeatPrice: number;
  monthlyDiscountedTotal: number;
  monthlyCopilotPriceForSeats: number;
  monthlyTotal: number;
  totalPrice: number;
  updateShowCreditCardPage: () => void;
  confirmPayment: () => void;
  showMeteredMessagesArticle: () => void;
  //Early stage args
  earlyStageProgramName?: string;
  isEarlyStage?: boolean;
  earlyStageDiscountAmounts?: {
    yearOne: number;
    yearTwo: number;
    yearThree: number;
  };
  expectedTrialEndDate?: Date;
  hasCopilotEarlyStageDiscount?: boolean;
  isEarlyStageProgression?: boolean;
  showEarlyStagePricingArticle?: () => void;
  openEarlyStageProgressionModal?: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class Basket extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  get subHeading() {
    return this.intl.t(
      `signup.teams.pricing5.annual-plans.basket.subheading.${this.args.selectedPlanId}`,
    );
  }

  get actualSeatPrice() {
    return this.args.showDiscount ? this.args.discountedSeatPrice : this.args.fullSeatPrice;
  }

  get isProactiveAddonDiscounted() {
    return this.args.fullProactiveAddonPrice > this.args.discountedProactiveAddonPrice;
  }

  get yearlySavings() {
    return this.quoteService.formatPrice(
      this.args.monthlyTotal * 12 - this.args.monthlyDiscountedTotal * 12,
    );
  }

  get formattedTotalPrice() {
    return this.intl.formatNumber(this.args.totalPrice, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::Basket': typeof Basket;
  }
}
