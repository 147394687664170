/* import __COLOCATED_TEMPLATE__ from './trigger-config.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { tracked } from '@glimmer/tracking';

interface Args {}

export default class StandaloneSetupTicketsTriggerConfig extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked isSelectingUser = false;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get triggerUrl() {
    if (!this.ticketsSetupData.live_trigger?.id) {
      return null;
    }

    return `https://${this.zendeskConfig.subdomain}.zendesk.com/admin/objects-rules/rules/triggers/${this.ticketsSetupData.live_trigger.id}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Tickets::TriggerConfig': typeof StandaloneSetupTicketsTriggerConfig;
    'standalone/setup/tickets/trigger-config': typeof StandaloneSetupTicketsTriggerConfig;
  }
}
