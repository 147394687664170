/* import __COLOCATED_TEMPLATE__ from './channels.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';

interface Args {}

export default class StandaloneSunshineChannels extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  @use channels = AsyncData<Array<any>>(async () => {
    // Once Sunshine is connected, this resource will rerun and load the channels.
    if (this.zendeskConfig.isPendingSetupForSunshine) {
      return [];
    }

    let sunshineChannels = this.finStandaloneService.sunshineChannels;
    let zendeskBrands = this.finStandaloneService.zendeskBrands;

    let result = sunshineChannels.map((channel) => {
      let brand = zendeskBrands.find((brand) => String(brand.id) === String(channel.brandId));
      return {
        id: channel.id,
        name: channel.displayName,
        defaultResponder: channel.defaultResponder,
        brand,
      };
    });

    return result;
  });

  @action async toggleChannel(channel: any) {
    if (
      this.zendeskConfig.sunshineIntegration.supportedChannelIntegrationIds.includes(channel.id)
    ) {
      await this.removeFinFromChannel(channel.id);
    } else {
      await this.addFinToChannel(channel.id, channel.brand.id);
    }
  }

  @action async removeFinFromChannel(channelId: string) {
    try {
      await this.zendeskConfig.sunshineIntegration.removeFinFromChannel(channelId);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't remove Fin from the channel. Please try again`,
      });
    }
  }

  @action async addFinToChannel(channelId: string, brandId: string) {
    try {
      await this.zendeskConfig.sunshineIntegration.addFinToChannel(channelId, brandId);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't add Fin to the channel. Please try again`,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Sunshine::Channels': typeof StandaloneSunshineChannels;
    'standalone/setup/sunshine/channels': typeof StandaloneSunshineChannels;
  }
}
