/* import __COLOCATED_TEMPLATE__ from './configure-plan.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PLAN_DATA, P5_CORE_PLAN_UPGRADE_FEATURES } from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  corePlans: Array<Plan>;
  yearlyBillingPeriodSelected: boolean;
  selectedPlanId: string;
  seatNumber: number;
  minimumSeatNumber: number;
  selectedProactiveAddon: boolean;
  selectedCopilotAddon: boolean;
  selectedCopilotAdmins: Array<string>;
  copilotInitialised: boolean;
  fullSeatPrice: number;
  discountedSeatPrice: number;
  fullProactiveAddonPrice: number;
  perSeatCopilotAddonPrice: number;
  discountedCopilotSeatPrice: number;
  proactiveSupportBaseUsage: string;
  loading: boolean;
  updateSeatNumber: (seatNumber: number) => void;
  updateSelectedPlan: (plan: Plan) => void;
  setSelectedProactiveAddon: (selectedProactiveAddon: boolean) => void;
  setCopilotAsInitialised: (selectedCopilotAddon: boolean) => void;
  showProactiveSupportProArticle: () => void;
  isEarlyStageProgression: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class ConfigurePlan extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;
  @service declare appService: any;

  @tracked isCopilotSelectorModalOpen = false;

  get planFeatureKeys(): any {
    return P5_CORE_PLAN_UPGRADE_FEATURES[this.args.selectedPlanId];
  }

  get proactiveSupportPlusFeatures() {
    return [
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.product-tours`,
        ),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.carousels`,
        ),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.checklists`,
        ),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.push-notifications`,
        ),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.surveys`,
        ),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.proactive-support.features.posts`,
        ),
      },
    ];
  }

  get copilotFeatures() {
    return [
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.copilot.features.continuous-learning`,
        ),
      },
      {
        text: this.intl.t(`signup.teams.pricing5.annual-plans.upgrades.copilot.features.trusted`),
      },
      {
        text: this.intl.t(
          `signup.teams.pricing5.annual-plans.upgrades.copilot.features.troubleshooting`,
        ),
      },
      {
        text: this.intl.t(`signup.teams.pricing5.annual-plans.upgrades.copilot.features.insights`),
      },
    ];
  }

  get seatNumber() {
    return this.args.seatNumber;
  }

  set seatNumber(value: number) {
    let seatNumber: number;
    if (value <= this.args.minimumSeatNumber) {
      seatNumber = this.args.minimumSeatNumber;
    } else if (value > 100) {
      seatNumber = 100;
    } else {
      seatNumber = value;
    }
    this.args.updateSeatNumber(seatNumber);
  }

  get notLowestTierPlan() {
    let plan = this.args.corePlans.find((plan) => plan.id === this.args.selectedPlanId) as Plan;
    return plan.tierId > 1;
  }

  get isPlanWithLiteSeatsSelected() {
    return PLAN_DATA[this.args.selectedPlanId].liteSeatLimit > 0;
  }

  get planList() {
    return this.args.corePlans.map((plan: Plan) => {
      return {
        text: plan.selfServe
          ? plan.name
          : this.intl.t(PLAN_DATA[plan.id].checkoutTitleTranslationKey, { htmlSafe: true }),
        description: this.intl.t(PLAN_DATA[plan.id].checkoutDescriptionTranslationKey),
        value: plan.id,
      };
    });
  }

  get featuresLeftCol() {
    return this.planFeatureKeys.left;
  }

  get featuresRightCol() {
    return this.planFeatureKeys.right;
  }

  get seatPrice() {
    return this.args.yearlyBillingPeriodSelected
      ? this.args.discountedSeatPrice
      : this.args.fullSeatPrice;
  }

  get showCopilotAddonCard() {
    return this.args.isEarlyStageProgression
      ? this.args.selectedCopilotAdmins.length > 0
      : this.appService.app.copilotProduct;
  }

  @action updateSelectedPlan(planId: string) {
    // this will not be undefined but typescript isn't happy
    let plan = this.args.corePlans.find((plan) => plan.id === planId) as Plan;
    this.args.updateSelectedPlan(plan);
  }

  @action toggleCopilotSelectorModal() {
    this.isCopilotSelectorModalOpen = !this.isCopilotSelectorModalOpen;
  }

  @action confirmCopilotSelection() {
    this.toggleCopilotSelectorModal();
    this.args.setCopilotAsInitialised(true);
  }

  @action toggleEsProgressionCopilotSelection() {
    this.args.setCopilotAsInitialised(!this.args.selectedCopilotAddon);
  }

  @action toggleAddonSelectedState() {
    if (this.args.isEarlyStageProgression) {
      this.toggleEsProgressionCopilotSelection();
    } else {
      this.toggleCopilotSelectorModal();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::ConfigurePlan': typeof ConfigurePlan;
  }
}
